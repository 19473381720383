<template lang="pug">

  q-form(@submit="onSubmit")
    .row.justify-center.q-col-gutter-sm.q-pt-lg(v-if="config").q-pa-sm
      .col-sm-9.col-md-8.col-12
        .text-h4.text-center.q-pb-sm.q-mt-xl.text-bold {{ $t('phase.phase_' + phase) }}

        .text-center.text-body1.text-weight-light.q-my-lg.q-mb-xl  {{ $t('upload_again') }}

        //- .text-center
        //-   q-btn(flat color="primary" type="a" target="_BLANK" href="https://docs.google.com/document/d/1Y-I2JgZkURrjbQQXOkgwkjF8dZe9uiRTtbkSw-VaVzE/edit?usp=sharing") {{$t('read_guide')}}
       
        PhaseSubmission(
          ref="formInput",
          :phase="phase"
          :files="files",
          :config="config"
          :submission="submission",
          :countryList="regions",
          :commsLanguages="commsLangs",
          :languageList="languages",
          :tagOptions="tagOptions",
          :selectedTags="selectedTags",
          v-on:rejected="onRejected"
        )
    
    .row.justify-center.q-col-gutter-sm.q-mt-xl(v-if="config" style="background:#F8EEDC").q-px-sm.q-pb-xl
      .vicgoveline
      .col-sm-9.col-md-8.col-12
        div()
          //- metadata
          .text-center.q-my-lg.q-pt-lg
            //- q-icon(:name="matInfo", size="lg")
            .text-h4.text-bold.q-mt-xl {{ $t('information') }}

          q-input.full-width.q-mb-sm(
            outlined,
            bg-color="vicgov"
            color="primary"
            v-model="submission.formdata.eventname",
            :label="$t('event_name')",            
            :rules="[(val) => !!val || $t('event_name_required')]"
          )
            template(v-slot:prepend)
              q-icon(:name="matFormatQuote", size="sm" color="primary")
          
          q-field.q-mb-sm.full-width(bg-color="vicgov" outlined :label="$t('event_date')" v-model="submission.formdata.eventdate" :rules="[(val) => (val!== null && val.length>0) || $t('eventdate_required')]")
            template(v-slot:prepend)
              q-icon(:name="matEvent", size="sm" color="primary" )
            template(v-slot:control)
              div
                q-chip.q-mr-xs(:key="d" v-for="d of submission.formdata.eventdate") {{d}}
            template(v-slot:append)
              q-btn(flat :icon="matEvent" class="cursor-pointer" dense round color="black").bg-secondary
                q-popup-proxy(ref="qDateProxy" transition-show="scale" transition-hide="scale")
                  q-date(
                    outlined,
                    multiple
                    v-model="submission.formdata.eventdate",
                    :label="$t('event_date')",            
                    
                  )
                    div.row.items-center.justify-end
                      q-btn(v-close-popup label="Close" color="primary" flat)


          q-input.q-mb-sm.full-width(
            outlined,
            bg-color="vicgov"
            v-model="submission.formdata.address",
            :label="$t('address')",          
            :rules="[(val) => !!val || $t('address_required')]"
          )
            template(v-slot:prepend)
              q-icon(:name="matMyLocation", size="sm" color="primary")

          q-input.q-mb-sm.full-width(
            outlined,
            bg-color="vicgov"
            v-model="submission.formdata.postcode",
            :label="$t('postcode')",          
            mask="####"
            :rules="[(val) => !!val || $t('postcode_required')]"
          )
            template(v-slot:prepend)
              q-icon(:name="matLocationCity", size="sm" color="primary")
          q-input.q-mb-sm.full-width(
            outlined,
            bg-color="vicgov"
            v-model.number="submission.formdata.attendees",
            :label="$t('attendees')",  
            type="number"
            min=0          
            :rules="[(val) => !!val || $t('attendees_required'),(val) => val>0 || $t('attendees_required')]"
          )
            template(v-slot:prepend)
              q-icon(:name="matGroups", size="sm" color="primary")

          q-input.q-mb-sm.full-width(
            outlined,
            bg-color="vicgov"
            v-model.number="submission.formdata.volunteers",
            :label="$t('volunteers')",  
            type="number"
            min=0          
            :rules="[(val) => !!val || $t('attendees_required'),(val) => val>0 || $t('attendees_required')]"
          )
            template(v-slot:prepend)
              q-icon(:name="matPerson", size="sm" color="primary")

          q-select.q-mb-sm.full-width(
            outlined,
            bg-color="vicgov"
            v-model="submission.formdata.ethnicgroup",
            :label="$t('target_ethnic_group')",
            :options="ethnicGroups",
            :rules="[(val) => !!val || $t('target_ethnic_group_required')]"
          )
            template(v-slot:prepend)
              q-icon(:name="matDiversity3", size="sm" color="primary")

          q-select.q-mb-sm.full-width(
            outlined,
            bg-color="vicgov"
            v-model="submission.formdata.languagegroup",
            :label="$t('target_language_group')",
            :options="languageGroups",
            :rules="[(val) => !!val || $t('target_language_group_required')]"
          )
            template(v-slot:prepend)
              q-icon(:name="matTranslate", size="sm" color="primary")

          q-select.q-mb-sm.full-width(
            outlined,
            bg-color="vicgov"
            v-model="submission.formdata.faithgroup",
            :label="$t('target_faith_group')",
            :options="faithGroups",
            :rules="[(val) => !!val || $t('target_faith_group_required')]"
          )
            template(v-slot:prepend)
              q-icon(:name="matDiversity2", size="sm" color="primary")

          //- q-select.full-width(
          //-   outlined,
          //-   v-model="submission.language",
          //-   :label="$t('language_recorded_in')",
          //-   emit-value,
          //-   map-options,
          //-   :options="recordingLangs",
          //-   :rules="[(val) => !!val || $t('language_required')]"
          //- )
          //-   template(v-slot:before)
          //-     q-icon(:name="matTranslate", size="lg")

          //- q-field.full-width(
          //-   outlined,
          //-   ref="tags",
          //-   :error="!hasTags",
          //-   :error-message="$t('tag_required')"
          //- )
          //-   .q-py-md
          //-     q-chip.q-pl-md.q-pr-sm(
          //-       :key="tag.value",
          //-       size="lg",
          //-       v-for="tag of tagOptions",
          //-       :selected.sync="selectedTags[tag.value]",
          //-       :outline="!selectedTags[tag.value]",
          //-       :dark="selectedTags[tag.value]",
          //-       color="primary",
          //-       text-color="white",
          //-       :icon="tag.icon",
          //-       :icon-selected="tag.icon"
          //-     )
          //-       q-tooltip {{ tag.label }}
          //-   template(v-slot:before)
          //-     q-icon(:name="matStyle", size="lg", color="grey-8")

          //- q-select.q-pt-md.full-width(
          //-   outlined,
          //-   v-model="submission.region",
          //-   emit-value,
          //-   map-options,
          //-   :label="$t('country_im_in')",
          //-   :options="regions",
          //-   :rules="[(val) => !!val || $t('country_required')]"
          //- )
          //-   template(v-slot:before)
          //-     q-icon(:name="matPublic", size="lg")
          //-   template(v-slot:selected-item="scope")
          //-     q-item(v-bind="scope.itemProps", v-on="scope.itemEvents")
          //-       q-item-section.flag(avatar) {{ scope.opt.flag }}
          //-       q-item-section
          //-         q-item-label {{ scope.opt.label }}

          //-   template(v-slot:option="scope")
          //-     q-item(v-bind="scope.itemProps", v-on="scope.itemEvents")
          //-       q-item-section.flag(avatar) {{ scope.opt.flag }}
          //-       q-item-section
          //-         q-item-label {{ scope.opt.label }}

          //- q-select.full-width(
          //-   outlined,
          //-   v-model="submission.commslanguage",
          //-   :label="$t('language_we_will_use_when_messaging_you')",
          //-   emit-value,
          //-   map-options,
          //-   :options="commsLangs",
          //-   :rules="[(val) => !!val || $t('communication_language_required')]"
          //- )
          //-   template(v-slot:before)
          //-     q-icon(:name="matEmail", size="lg")

    .row.justify-center.q-col-gutter-sm(v-if="config" ).q-px-sm.bg-primary.q-pb-xl
      .col-sm-9.col-md-8.col-12
        .q-mt-xl
          q-field(
            outlined,
            color="black",
            no-error-icon
            bg-color="vicgov",
            :error="!hasAccepted"
            :bottom-slots="true"
          )
            template(v-slot:error)
              .float-right
                p.text-white
                  q-icon(:name="matWarning" size="2em" color="white").q-mr-xs
                  span {{$t('accept_terms_error')}}
                  
            .full-width.q-py-sm
              //- .text-body1 {{ $t('media_release_title') }}
              
              .text-body1 By submitting this video acquittal, you certify that the funding has been used for its intended purpose in accordance with the Activity outlined in the Agreement between the Department and the Organisation. To the best of your knowledge, the information disclosed in this video acquittal:
              ul
                li Discloses all relevant information that the Organisation is required to report on in relation to the Activity.
                li Is true, correct, and without false or misleading statements.
                li Is provided after conducting all necessary searches, investigations, and enquiries.
                li You acknowledge that the Department may seek additional information from the Organisation for the purpose of clarifying information provided in this video acquittal and attached supporting documentation.

              .text-body1 You also agree to the following terms and conditions, further information is available here 
                span <a href="/policy" target="_blank">{{ $t('terms_privacy_policy') }}</a>
              ul
                li You are a representative from the Organisation receiving funding under the 2022-23 Multicultural Festivals and Events Program.
                li You are over 18 years old.
                li You have not included any images or audio that are considered inflammatory, illegal, offensive, or pornographic in nature, or otherwise against the Fundamental Principles of the Victorian Government.
                li The content you have submitted can be used, distributed, and archived by the Department of Families, Fairness, and Housing on any public broadcast channel.
                li The content submitted can be further edited into content that may be shared on social media by the Department of Families, Fairness, and Housing.
                li You have permission to use the videos uploaded here.
                li You have not deliberately or maliciously misrepresented any person in the content you are uploading.

              .text-right
                q-toggle(
                  color="primary"
                  v-model="accept",
                  left-label,
                  :label="$t('accept_agreement')",

                  size="lg"
                )
            template(v-slot:prepend) 
              q-icon(:name="matPolicy" size="sm" color="primary")
        .row.q-mt-lg.justify-between
          .col-auto
            q-btn(
              to="/submission",
              :icon="matWest",
              size="lg",
              flat,
              rounded,
              no-caps
              color="white"
            ) {{ $t('back') }}
          .col-auto
            q-btn(
              color="secondary",
              text-color="black"
              :icon-right="matSend",
              unelevated,
              no-caps
              size="lg",
              :label="$t('submit')",
              type="Submit"
            )
    .fixed-full.bg-primary(v-if="uploading")
      .dimmed
        .dimmed
          .z-top.absolute-center.text-white.text-center
            .text-h3(style="max-width: 700px; width: 80vw") {{ $t('uploading') }}
            .text-body1.q-mt-md {{ $t('dont_close') }}
            q-linear-progress.q-my-lg(size="15px", :value="progress", rounded)
</template>

<script>
/* eslint-disable no-undef */
import { mapActions, mapState, mapGetters } from "vuex";
import PhaseSubmission from "@/components/forms/PhaseSubmission";
// const regions = require("@/ifrclist.json");
import { ifrcList } from "ifrc-list";
const langmap = require("@/language-mapping-list");
import each from "lodash/each";
import map from "lodash/map";
import zipObject from "lodash/zipObject";
import fill from "lodash/fill";
import size from "lodash/size";
import uniq from "lodash/uniq";
import compact from "lodash/compact";
import Language from "@/mixins/Language";
import { EventBus } from "@/event-bus";
import { fabYoutube } from "@quasar/extras/fontawesome-v5";
import langs from "../langs";
const formoptions = require("../formoptions.json");

export default {
  name: "SubmissionForm",
  mixins: [Language],
  components: {
    PhaseSubmission,
  },
  props: ["selectedphase"],
  watch: {
    selectedTags: {
      deep: true,

      handler() {
        let arr = [];
        for (const [k, v] of Object.entries(this.selectedTags)) {
          if (v) arr.push(k);
        }

        this.submission.tags = arr;
      },
    },
    loading: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$q.loading.show({
            delay: 400, // ms
          });
        } else {
          this.$q.loading.hide();
        }
      },
    },
  },
  async created() {
    this.loading = true;
    this.fabYouTube = fabYoutube;
    try {
      await Promise.all([
        this.initConfig(),
        this.getSubmissions(true),
      ]);
    } catch (e) {
      console.log("Error, rerouting")
      this.$router.replace("/submission");
    }

    //check if they already have a submission
      
    if (!this.canSubmit()(this.selectedphase)) {
      this.$router.replace("/submission");
    }

    EventBus.$on("upload_progress", this.updateProgress);

    if (this.config.tag_options) {
      this.selectedTags = zipObject(
        Object.keys(this.config.tag_options),
        fill(Array(size(this.config.tag_options)), false)
      );
    }

    this.loading = false;
  },
  computed: {
    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
    hasTags() {
      return this.submission.tags.length && this.submission.tags.length < 4;
    },
    hasAccepted() {
      return this.accept;
    },
    phase() {
      // USE ROUTE IF SET TO CALCULATE PHASE:
      if (this.selectedphase) return this.selectedphase;
      else return this.config.current_phase;
    },
    recordingLangs() {
      let lngs = [];
      for (let l of langs)
        lngs.push({
          label: this.codeToLang(l),
          value: l,
        });
      lngs.push({
        label: this.$t("other"),
        value: "en-US",
      });
      return lngs;
    },
    commsLangs() {
      let lngs = [];
      for (let l of langs)
        lngs.push({
          label: this.codeToLang(l),
          value: l,
        });
      return lngs;
    },
    languages() {
      let tmp = [];
      each(langmap, (a, k) => {
        if (k.length == 2)
          tmp.push({
            value: k,
            label: a.nativeName,
          });
      });
      return tmp;
    },
    regions() {
      return map(ifrcList, (r) => {
        return {
          value: r.code,
          flag: r.flag,
          label: r.name,
        };
      });
    },
    tagOptions() {
      return map(this.config.tag_options, (k, v) => {
        return {
          label: this.$t("tags." + v),
          value: v,
          icon: this.getTagIcon(v),
        };
      });
    },
    ...mapState(["config"]),
    ...mapGetters(["getTagIcon"]),
  },
  methods: {
    updateProgress(progress) {
      this.progress = progress;
    },
    async onSubmit() {
      try {
        // if (!(await this.$refs.formInput).validateMe()) {
        //   this.uploading = false;
        //   return;
        // }

        if (this.accept !== true) {
          this.uploading = false;
          return;
        }

        if (this.$refs.tags && this.$refs.tags.error) {
          this.uploading = false;
          return;
        }

        //check files are different:
        let names = compact(map(this.files, "name"));
        // console.log(uniq(names));
        // console.log(names);

        if (uniq(names).length !== names.length) {
          //not unique files:
          this.uploading = false;

          this.$q.notify({
            type: "negative",
            message: this.$t("resubmit_message"),
          });
          return;
        }

        this.uploading = true;

        // this.submission.language = this.submission.language.substr(0,2);

        //HARDCODE LANGUAGE:
        this.submission.language = "en";
        this.submission.commslanguage = "en";

        await this.submitAction({
          submission: this.submission,
          files: this.files,
          phase: this.phase,
        });

        //artificial delay to wait for the firebase function to set new state:
        setTimeout(async () => {
          await this.getSubmissions(true);
          this.$router.replace("/submission");
        }, 3000);
      } catch (e) {
        console.log(e);
        //report failure
        this.showError(this.$t("submission_failed"));
        this.uploading = false;
      }
    },
    onRejected() {
      this.$q.notify({
        color: "secondary",
        textColor: "white",
        icon: this.matWarning,
        message: this.$t("video_size_warning"),
      });
    },
    ...mapActions(["submitAction", "initConfig", "getSubmissions"]),
    ...mapGetters(["canSubmit"]),
  },
  data() {
    return {
      selectedTags: {},
      submission: { tags: [], formdata: {eventdate:[]} },
      files: [null, null, null, null, null],
      accept: false,
      uploading: false,
      progress: 0,
      loading: true,
      languageGroups: formoptions.language_groups,
      ethnicGroups: formoptions.ethnic_groups,
      faithGroups: formoptions.faith_groups
    };
  },
};
</script>

<style src="@platyplus/humanitarian-icons/dist/icons.css">
/* global styles */
</style>

<style lang="scss" scoped>


.info {
  font-size: 1.5em;
}

.flag {
  font-size: 2.5em;
}
</style>

<style lang="scss">
@import "@/styles/quasar.variables.scss";
// .q-chip--selected .q-chip__icon img {
// background:green !important;
//   filter: invert(1) !important;
//   display: none;
// }
.q-chip--selected {
  img.q-chip__icon {
    // background:green !important;
    filter: brightness(100);
    // display: none;
  }
}

.bg-vicgov {
  background-color: #FFF9ED;
}

.vicgoveline {
  border-radius: 50% 50% 50% 50% / 4% 4% 96% 96%;
  background-color:  #FFF9ED;
  width:100%;
  height:20px;
  // margin-top:20px;
  position: absolute;
  margin-top:-1px;
}

.q-field--outlined .q-field__control:before {
  border-color: $primary !important;
}
</style>
